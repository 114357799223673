import {Alert, AlertTitle, Autocomplete, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import ProcessingProtocolType from "../customTypes/ProcessingProtocolType";
import {fetchProcessingProtocols} from "../services/ProcessingProtocolService";

export default function ProcessingProtocol(props: { handleChange: (processingProtocol: ProcessingProtocolType | null) => void, value: string | null }) {
    const [processingProtocols, setProcessingProtocols] = useState<Array<ProcessingProtocolType>>([])
    const [errors, setErrors] = useState<Array<string | undefined>>([]);

    const getAllProcessingProtocols = async () => {
        try {
            const processingProtocols = await fetchProcessingProtocols()
            setProcessingProtocols(processingProtocols)
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    const handleProcessingProtocolChange = (e: React.SyntheticEvent, value: string|null) => {
        const processingProtocol = processingProtocols.find(p => p.protocolName === value)
        props.handleChange(processingProtocol || null)
    }

    useEffect(() => {
        getAllProcessingProtocols()
    }, [])

    const processingProtocolNames = processingProtocols.map((processingProtocol) => processingProtocol.protocolName)

    return (
        <>
            {errors.length > 0 && <Alert severity="error">
                <AlertTitle>Lookup processing-protocol error(s):</AlertTitle>
                <ul>
                    {errors.map((error, index) => {
                        return (<li key={index}>{error}</li>)
                    })}
                </ul>
            </Alert>}
            <Autocomplete
                id={"processingProtocol"}
                value={props.value}
                onChange={handleProcessingProtocolChange}
                disablePortal
                options={processingProtocolNames.sort((a:any, b:any) => -b.localeCompare(a))}
                renderInput={(params) => <TextField {...params} required label="Processing Protocol"/>}
                fullWidth
            />
        </>
    );
}