import { Box, Button, Chip, Drawer, Grid, IconButton, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DataGrid, GridCallbackDetails, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import PersonType from '../../customTypes/PersonType';
import { IProject } from '../../customTypes/ProjectsApiResponseType';
import { getAllProjects } from "../../services/apiCalls";
import DocumentType from '../../customTypes/DocumentType';
import { useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';

const renderHeader = ({ field }: GridColumnHeaderParams) => {
  return <Typography variant="overline" display="block">{field}</Typography>;
};

function ViewProjects() {
  const [projects, setProjects] = useState<Array<IProject>>([])
  const [errors, setErrors] = useState<Array<string | undefined>>([]);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [toggleDrawer, setToggleDrawer] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<IProject>();

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'id',
      flex: 0.5,
      renderHeader,
      renderCell: (params: GridRenderCellParams) => {
        const { id } = params.row;
        return <Box onClick={(e: any) => e.stopPropagation()}>
          <CopyToClipboard text={id} onCopy={() => setOpenSnackBar(true)}>
          <Tooltip title={id}>
            <IconButton color="primary" component="label" size="small">
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
        </Box>
      }
    },
    {
      field: 'Project',
      flex: 4,
      renderHeader,
      renderCell: (params: GridRenderCellParams) => {
        const { project_name, project_description, start_date, end_date } = params.row;
        return <div>
          <Typography variant="h6">{project_name}</Typography>
          <Typography variant="subtitle2">{project_description.length > 75 ? `${project_description.substring(0, 75)}...` : project_description}</Typography>
          <Typography variant="overline" display="block" gutterBottom>{start_date} - {end_date}</Typography>
        </div>
      },
    },
    {
      field: 'Team Members',
      flex: 4,
      renderHeader,
      renderCell: (params: GridRenderCellParams) => {
        const { project_owner, team_members } = params.row;
        return <div>
          <Tooltip title="Project Owner" placement="top" arrow>
            <Chip icon={<FaceIcon />}
              label={project_owner.name}
              sx={{ mr: '5px', mb: '5px' }}
              color="secondary"
              component="a"
              href={`mailto:${project_owner.email}`}
              target="_blank"
              rel="noopener noreferrer nofollow"
              onClick={(e: any) => e.stopPropagation()} />
          </Tooltip>
          {team_members.map(({ name, email }: PersonType) => {
            return <Chip
              key={name}
              icon={<FaceIcon />}
              label={name}
              variant="outlined"
              sx={{ mr: '5px', mb: '5px' }}
              component="a"
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer nofollow"
              onClick={(e: any) => e.stopPropagation()}
            />
          })}
        </div>
      }
    },
    {
      field: 'Funding Structure',
      renderHeader,
      renderCell: (params: GridRenderCellParams) => params.row.funding_structure,
      flex: 2.5
    },
    {
      field: 'Partner Org.',
      flex: 2.5,
      renderHeader,
      renderCell: (params: GridRenderCellParams) => {
        const { partner_organisations } = params.row;
        return partner_organisations ? <Box sx={{ border: '1px solid #DDD', p: '5px', bgcolor: '#FFF' }}>{partner_organisations}</Box> : '-';
      }
    },
    {
      field: 'Funding Org.',
      flex: 2.5,
      renderHeader,
      renderCell: (params: GridRenderCellParams) => {
        const { funding_organisation } = params.row;
        return funding_organisation ? <Box sx={{ border: '1px solid #DDD', p: '5px', bgcolor: '#FFF' }}>{funding_organisation}</Box> : '-';
      }
    },
    {
      field: 'licenses',
      headerName: 'Licenses',
      flex: 2,
      renderHeader,
    },
    {
      field: 'Project Documents',
      flex: 4,
      renderHeader,
      renderCell: (params: GridRenderCellParams) => {
        const { project_documents } = params.row;
        const documents = project_documents.map(({ title, link }: DocumentType, index: number) => {
          return <Chip
            key={index}
            icon={<FileDownloadIcon />}
            size="small"
            label={title}
            variant="outlined"
            component="a"
            target="_blank"
            rel="noopener noreferrer nofollow"
            href={link}
            onClick={(e: any) => e.stopPropagation()}
            sx={{ mr: '5px', mb: '5px' }}
          />
        });
        return documents;
      }
    }
  ];

  useEffect(() => {
    showAllProjects()
  }, [])

  const showAllProjects = async () => {
    try {
      const projects = await getAllProjects()
      setProjects(projects);
    } catch (err: any) {
      setErrors([...errors, err.toString()])
    }
  }

  const handleRowClick = (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
    setSelectedProject(params.row);
    setToggleDrawer(true);
  }

  return (
    <Grid container direction="column" style={{ paddingLeft: 20 }}>
      <Grid item id="heading">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h3" gutterBottom>Projects</Typography>
          <Button variant="contained" color="warning" startIcon={<AddCircleIcon />} onClick={() => navigate('/projects/new')}>New Project</Button>
        </Stack>
      </Grid>
      <Grid item id="data" height={600}>
        <DataGrid
          rows={projects}
          getRowHeight={() => 'auto'}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          onRowClick={handleRowClick}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={openSnackBar}
          onClose={() => setOpenSnackBar(false)}
          message="ID is copied to clipboard"
          autoHideDuration={5000}
        />
      </Grid>
      <React.Fragment key='project-drawer'>
        <Drawer
          anchor="right"
          open={toggleDrawer}
          onClose={() => setToggleDrawer(false)}
        >
          <Box sx={{ width: '25vw', p: '15px', bgcolor: '#EEE', height: '100%' }}>
            <Typography variant='h5' gutterBottom>{selectedProject?.project_name}</Typography>
            <Typography variant="overline" display="block">Project Description</Typography>
            <Typography variant="body2" gutterBottom>
              {selectedProject?.project_description}
            </Typography>
            <Stack direction="row" spacing={5}>
              <Box>
                <Typography variant="overline" display="block">Start Date</Typography>
                <Typography variant="overline" display="block">{selectedProject?.start_date}</Typography>
              </Box>
              <Box>
                <Typography variant="overline" display="block">End Date</Typography>
                <Typography variant="overline" display="block">{selectedProject?.end_date}</Typography>
              </Box>
            </Stack>
            <Typography variant="overline" display="block">Project Owner</Typography>
            <Box><Chip icon={<FaceIcon />} label={selectedProject?.project_owner.name} sx={{ mr: 1, mb: 1 }} color="secondary" /></Box>

            <Box sx={{ mb: 2 }}>
              <Typography variant="overline" display="block">Team Members</Typography>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Name</strong></TableCell>
                      <TableCell><strong>E-mail</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedProject?.team_members.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <a href={`mailto:${row.email}`}>{row.email}</a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <Typography variant="overline" display="block">Funding Structure</Typography>
              <Typography variant="body2">{selectedProject?.funding_structure}</Typography>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <Typography variant="overline" display="block">Partner Organizasion</Typography>
              <Paper sx={{ p: 2 }}>
                <Typography variant="body2">{selectedProject?.partner_organisations}</Typography>
              </Paper>
            </Box>

            <Box sx={{ mb: 2, mt: 2 }}>
              <Typography variant="overline" display="block">Funding Organizasion</Typography>
              <Paper sx={{ p: 2 }}>
                <Typography variant="body2">{selectedProject?.funding_organisation}</Typography>
              </Paper>
            </Box>

            <Box sx={{ mb: 2, mt: 2 }}>
              <Typography variant="overline" display="block">Licenses</Typography>
              <Typography variant="body2" display="block">{selectedProject?.licenses}</Typography>
            </Box>

            <Box sx={{ mb: 2, mt: 2 }}>
              <Typography variant="overline" display="block">Documents</Typography>
              <Box>
                {selectedProject?.project_documents.map(({ title, link }: DocumentType, index: number) => {
                  return <Chip
                    key={index}
                    icon={<FileDownloadIcon />}
                    size="small"
                    label={title}
                    sx={{ mr: '5px', mb: '5px' }}
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href={link}
                    onClick={(e: any) => e.stopPropagation()}
                  />
                })}
              </Box>
            </Box>

          </Box>

        </Drawer>

      </React.Fragment>
    </Grid >
  )
}

export default ViewProjects;