import { Button, IconButton, Link, Stack } from "@mui/material";
import React from "react";
import PersonType from "../../customTypes/PersonType";
import Person from "./Person";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function People(
    props: {
        members: PersonType[],
        handleAddPerson: (event: any) => void,
        removePerson: (index: number) => void,
        handlePersonChange: (index: number, person: PersonType) => void,
        labelPrefix?: string
    }) {
    return (
        <>
            <Stack spacing={1} sx={{ mb: 1 }}>
                {props.members.map((person, index) => {
                    return <React.Fragment key={index}>
                        <Stack spacing={1} direction="row">
                            <Person value={person}
                                prefix={props.labelPrefix || ''}
                                required={props.members.length === 1}
                                onChange={(person) => props.handlePersonChange(index, person)} />
                            {props.members.length > 1 && <IconButton onClick={() => props.removePerson(index)}><RemoveCircleIcon /></IconButton>}
                        </Stack>

                    </React.Fragment>
                })}
            </Stack>
            <Button startIcon={<AddCircleIcon />} onClick={props.handleAddPerson}>Add New</Button>
        </>
    )
}

export default People;