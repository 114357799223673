import { Button, IconButton, Link, Stack } from "@mui/material";
import React from "react";
import DocumentType from "../../customTypes/DocumentType";
import Document from "./Document";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function Documents(
    props: {
        documents: DocumentType[],
        handleAddDocument: (event: any) => void,
        removeDocument: (index: number) => void,
        handleDocumentChange: (index: number, document: DocumentType) => void,
        minDocuments?: number,
        prefix?: string
    }) {
    return (
        <>
            <Stack spacing={1} sx={{ mb: 1 }}>
                {props.documents.map((document, index) => {
                    return <React.Fragment key={index}>
                        <Stack spacing={1} direction="row">
                            <Document value={document}
                                prefix={props.prefix || ''}
                                required={props.documents.length === (props.minDocuments === undefined ? 1 : props.minDocuments)}
                                onChange={(document) => props.handleDocumentChange(index, document)} />
                            {props.documents.length > (props.minDocuments === undefined ? 1 : props.minDocuments) && <IconButton onClick={() => props.removeDocument(index)}><RemoveCircleIcon /></IconButton>}
                        </Stack>
                    </React.Fragment>
                })}
            </Stack>
            <Button startIcon={<AddCircleIcon />} onClick={props.handleAddDocument}>Add Document</Button>
        </>
    )
}

export default Documents;