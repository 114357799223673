import CSVReader from "./components/processingOutputs/CSVReader"

function EDNA() {
    return(
        <>
            <h1>Upload processing outputs</h1>
            <CSVReader/>
        </>
    )
}

export default EDNA