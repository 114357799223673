import {Button} from "@mui/material";
import React, {ChangeEvent} from "react";

function UploadButton(props: {inputRef: React.MutableRefObject<any>, handleFile: (event: ChangeEvent<HTMLInputElement>) => Promise<void> }) {
    return(
        <Button
            component="label"
            variant="outlined"
            fullWidth>
            <input data-testid="upload" ref={props.inputRef} required type="file" accept=".csv" onChange={props.handleFile}/>
        </Button>
    )
}

export default UploadButton;
