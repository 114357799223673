import { Button, Card, CardActions, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ScienceIcon from '@mui/icons-material/Science';
import PollIcon from '@mui/icons-material/Poll';
import ClassIcon from '@mui/icons-material/Class';
import CalculateIcon from '@mui/icons-material/Calculate';
import { ReactElement } from "react";

function GridOfLinks(props: { links: { url: string, title: string, icon?: ReactElement, action: string, description?: string, }[] }) {
    const navigate = useNavigate();
    return (
        <Grid item>
            <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                {props.links.map(({ url, title, icon, description, action }) => (
                    <Grid item xs>
                        <Card sx={{ height: '100%' }}>
                            <Stack sx={{ height: '100%' }} justifyContent="space-between">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">{title}s</Typography>
                                    {description && <Typography variant="body2" color="text.secondary">{description}</Typography>}
                                </CardContent>
                                <CardActions>
                                    <Button startIcon={icon} variant="contained" color="warning" onClick={() => { navigate(url) }} size="medium" fullWidth>{`${action} ${title}`}</Button>
                                </CardActions>
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

function Dashboard() {

    const definitionLinks = [
        {
            url: "/projects/new",
            title: "Project",
            icon: <ScienceIcon sx={{ fontSize: 40 }} />,
            description: `Project is used to define a set of surveys that can be tied back to a specific undertaking defined
            by a overarching vision, a political scope (such as owner, funder etc) and a timeframe`,
            action: "Create"
        },
        {
            url: "/surveys/new",
            title: "Survey",
            icon: <PollIcon sx={{ fontSize: 40 }} />,
            description: `A survey defines a way to conduct a biodiversity or environment oriented study within the
            context of a specific purpose that ties back to the parent project's vision.`,
            action: "Create"
        },
        {
            url: "/protocols/sampling/new",
            title: "Sampling Protocol",
            icon: <ClassIcon sx={{ fontSize: 40 }} />,
            description: `Sampling protocol is the scientific method that is precisely followed to record visual wild-life
            observations or to collect physical samples etc.`,
            action: "Create"
        },
        {
            url: "/protocols/processing/new",
            title: "Processing Protocol",
            icon: <CalculateIcon sx={{ fontSize: 40 }} />,
            description: `Processing Protocol is the scientific method which is used to process a sample or to analyse a
            digital media such as an audio recording.`,
            action: "Create"
        }
    ]

    const eDnaLinks = [
        { url: "/eDNA/upload-samples", title: "Sample", action: "Upload" },
        { url: "/eDNA/upload-processed-outputs", title: "Processed Output", action: "Upload" },
        { url: "/eDNA/taxonomic-equivalence", title: "Taxonomic Equivalence", action: "Go To" },
        { url: "/eDNA/upload-observations", title: "Observation", action: "Upload" },
    ]

    return (<>
        <Grid container direction="column" style={{ padding: 20 }}>
            <Grid item id="definitions">
                <h2>Setup Definitions</h2>
            </Grid>
            <GridOfLinks links={definitionLinks} />
            <Grid item id="edna">
                <h2>eDNA</h2>
                <Typography variant="body2" sx={{mb: 3}}>
                    Data Ecosystem allows you to ingest samples data that were collected as part of an eDNA based survey. It also has the capability to then ingest a corresponding processed file that contains the raw sequencing information appended to existing data.
                </Typography>
            </Grid>
            <GridOfLinks links={eDnaLinks} />
        </Grid>
    </>)
}

export default Dashboard;