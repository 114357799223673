import React from "react";
import {TextField} from "@mui/material";

function DOI(props: {value: string | undefined, handleChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void})  {
    return(
        <TextField
            name="doi"
            value={props.value}
            onChange={(event) => props.handleChange(event)}
            label="Digital Object Identifier (DOI)"
            variant="outlined"
            fullWidth
            helperText={<span>This identifier is generated externally using a provider such as <a href="https://datacite.org" target="_blank" rel="noopener noreferrer nofollow">https://datacite.org</a></span>}
        />
    )
}

export default DOI;