import { Button, Grid, InputLabel, TextField } from "@mui/material";
import { CommunityScienceTaxonHierarchyType } from "../../customTypes/CommunityScienceSamplesApiResponseType";
import React, { useEffect } from "react";
import { performTVKLookup } from "../../services/apiCalls";

function TaxonField(
    props: {
        taxonVersionKey: string,
        setTaxonVersionKey: (taxonVersionKey: string) => void,
        taxonHierarchy: CommunityScienceTaxonHierarchyType,
        setTaxonHierarchy: (taxonHierarchy: CommunityScienceTaxonHierarchyType) => void,
        tvkLookupPerformed: boolean,
        setTvkLookupPerformed: (tvkLookupPerformed: boolean) => void
    })
{
    const [lowestFoundHierarchy, setLowestFoundHierarchy] = React.useState("No Lookup Performed")
    const [tvkField, setTVKField] = React.useState(props.taxonVersionKey)

    useEffect(() => {
        if(props.taxonVersionKey === ""){
            props.setTaxonHierarchy({} as CommunityScienceTaxonHierarchyType)
            props.setTvkLookupPerformed(false)
            alert("Please enter a TVK to lookup.")
            return
        }
        performTVKLookup(props.taxonVersionKey).then((response) => {
            if(response["match_status"] === "No Match Found"){
                props.setTaxonHierarchy({} as CommunityScienceTaxonHierarchyType)
                props.setTvkLookupPerformed(false)
                alert("No match found for the TVK entered. Please try again.")
                return
            }
            props.setTaxonHierarchy(response)
            props.setTvkLookupPerformed(true)
        }).catch((error) => {
            props.setTaxonHierarchy({} as CommunityScienceTaxonHierarchyType)
            props.setTvkLookupPerformed(false)
            alert("Error looking up TVK. Please try again.")
        })
    },[props.taxonVersionKey]);

    useEffect(() => {
        if(props.taxonHierarchy["species"]){
            setLowestFoundHierarchy("Species = " + props.taxonHierarchy["species"])
        } else if(props.taxonHierarchy["genus"]){
            setLowestFoundHierarchy("Genus = " + props.taxonHierarchy["genus"])
        } else if(props.taxonHierarchy["family"]){
            setLowestFoundHierarchy("Family = " + props.taxonHierarchy["family"])
        } else if(props.taxonHierarchy["order"]){
            setLowestFoundHierarchy("Order = " + props.taxonHierarchy["order"])
        } else if(props.taxonHierarchy["class"]){
            setLowestFoundHierarchy("Class = " + props.taxonHierarchy["class"])
        } else if(props.taxonHierarchy["superclass"]){
            setLowestFoundHierarchy("Superclass = " + props.taxonHierarchy["superclass"])
        } else if(props.taxonHierarchy["phylum"]){
            setLowestFoundHierarchy("Phylum = " + props.taxonHierarchy["phylum"])
        } else if(props.taxonHierarchy["kingdom"]){
            setLowestFoundHierarchy("Kingdom = " + props.taxonHierarchy["kingdom"])
        } else {
            setLowestFoundHierarchy("No Lookup Performed")
        }
    }, [props.taxonHierarchy]);

    return(
        <Grid item xs={12}>
            <InputLabel>Enter UKSI recommended TVK to Lookup</InputLabel>
            <TextField
                value={tvkField}
                inputProps={{min: 0, style: { textAlign: 'center' }}}
                onChange={(e) => setTVKField(e.target.value)}
                fullWidth
            />
            <InputLabel><b>Status: </b>{lowestFoundHierarchy}</InputLabel>
            <Button
                sx={{
                    margin: "2%"
                }}
                onClick={() => {props.setTaxonVersionKey(tvkField)}}
                color={props.tvkLookupPerformed ? "success" : "error"}
                variant="contained"
            >
                Lookup
            </Button>
        </Grid>
    )
}

export default TaxonField;