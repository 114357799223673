import {Box, Chip, Grid, IconButton, Snackbar, Stack, Tooltip, Typography} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridRowParams} from '@mui/x-data-grid';
import React, {useEffect, useState} from 'react';
import {FullProcessingOutputApiResponseType} from '../../customTypes/ProcessingOutputApiReponseType';
import {
    getAllFullProcessingOutputs,
    getAllProcessingProtocols,
    getSingleFullProcessedOutput
} from "../../services/apiCalls";
import CopyToClipboard from 'react-copy-to-clipboard';
import AttachmentType from "../../customTypes/AttachmentType";
import IProcessingProtocol from "../../customTypes/FullProcessingProtocol";

const renderHeader = ({field}: GridColumnHeaderParams) => {
    return <Typography variant="overline" display="block">{field}</Typography>;
};

function ViewProcessingOutputs() {
    const [processingProtocols, setProcessingProtocols] = useState<Array<IProcessingProtocol>>([])
    const [processingOutputs, setProcessingOutputs] = useState<Array<FullProcessingOutputApiResponseType>>([])
    const [errors, setErrors] = useState<Array<string | undefined>>([]);
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

    const columns: GridColDef[] = [
        {
            field: 'id',
            flex: 0.5,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const {id} = params.row;
                return <Box onClick={(e: any) => e.stopPropagation()}>
                    <CopyToClipboard text={id} onCopy={() => setOpenSnackBar(true)}>
                        <Tooltip title={id}>
                            <IconButton color="primary" component="label" size="small">
                                <ContentCopyIcon/>
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                </Box>
            }
        },
        {
            field: 'Processing Output ID',
            flex: 2,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const {id} = params.row;
                return <div>
                    <Typography variant="h6">{id}</Typography>
                </div>
            },
        },
        {
            field: 'Processing Protocol Name',
            flex: 2,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const {processing_protocol_id} = params.row;
                const processing_protocol = processingProtocols.find(p => p.id === processing_protocol_id);
                return <div>
                    <Typography variant="h6">{processing_protocol?.protocol_name}</Typography>
                </div>
            },
        },
        {
            field: 'Attached Files',
            flex: 6,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const {attachments} = params.row;
                return attachments.map(({filename, link}: AttachmentType, index: number) => {
                    return <Chip
                        key={index}
                        icon={<FileDownloadIcon/>}
                        size="small"
                        label={filename}
                        variant="outlined"
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href={link}
                        onClick={(e: any) => e.stopPropagation()}
                        sx={{mr: '5px', mb: '5px'}}
                    />
                });
            }
        },
    ];

    useEffect(() => {
        showAllProcessingOutputs()
    }, [])

    const showAllProcessingOutputs = async () => {
        try {
            const processingOutputs = await getAllFullProcessingOutputs()
            const processingProtocols = await getAllProcessingProtocols()
            setProcessingProtocols(processingProtocols);
            setProcessingOutputs(processingOutputs);
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    // Function that renders the full processing output (with metadata) as formatted JSON in another tab within
    // the users browser.
    // TODO in later version: add in UI component to render full JSON
    const handleRowClick = async (params: GridRowParams) => {
        try {
            const processingOutput = await getSingleFullProcessedOutput(params.row.id);
            const processingOutputJsonString = JSON.stringify(processingOutput, null, 2);
            const jsonWindow = window.open();
            jsonWindow?.document.open();
            jsonWindow?.document.write('<html><body><pre>' + processingOutputJsonString + '</pre></body></html>');
            jsonWindow?.document.close();
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    return (
        <Grid container direction="column" style={{paddingLeft: 20}}>
            <Grid item id="heading">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h3" gutterBottom>Processing Outputs</Typography>
                </Stack>
            </Grid>
            <Grid item id="data" height={600}>
                <DataGrid
                    rows={processingOutputs}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    onRowClick={handleRowClick}
                />
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={openSnackBar}
                    onClose={() => setOpenSnackBar(false)}
                    message="ID is copied to clipboard"
                    autoHideDuration={5000}
                />
            </Grid>
        </Grid>
    )
}

export default ViewProcessingOutputs;