import React from "react";
import { TableContainer, Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';

interface IProps {
    data: string[][]
}

interface IState {
}

class DisplayTabularData extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <TableContainer style={{ maxHeight: 450, maxWidth: '75%' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow key={0}>
                        {this.props.data[0].map((colheader, j) => (
                           <TableCell key={j}>{colheader}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.data.splice(1).map((row, i) => (
                        <TableRow key={i}>
                            {row.map((cell, j) => (
                                <TableCell key={j}>{cell}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        )
    }
}

export default DisplayTabularData;