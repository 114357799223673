import React, { ChangeEvent, useRef, useState } from "react";
import { Alert, AlertTitle, Button, Divider, Grid, Typography } from "@mui/material";
import Project from "../../../commonComponents/Project";
import SurveyType from "../../../customTypes/SurveyType";
import Survey from "../../../commonComponents/Survey";
import Papa, { ParseResult } from "../../../services/PapaParsePromise";
import { createSamples } from "../../../services/SampleService";
import APIResponseType from "../../../customTypes/APIResponseType";
import UploadButton from "../../../commonComponents/UploadButton";
import ProjectType from "../../../customTypes/ProjectType";

function UploadSamplesForm() {
    const [project, setProject] = useState<ProjectType | null>(null);
    const [formData, setFormData] = useState<any | undefined>({});
    const [responses, setResponses] = useState<Array<APIResponseType<any>>>([]);
    const inputRef = useRef<any>(null);

    const handleProjectChange = async (project: ProjectType | null) => {
        setProject(project)
        if (project != null) {
            const { id, projectName } = project
            setFormData((values: any) => ({ ...values, projectId: id, projectName: projectName }))
        } else {
            setFormData((values: any) => ({ ...values, projectId: null, projectName: null }))
        }
    }

    const handleSurveyChange = (survey: SurveyType | null) => {
        if (survey != null) {
            const { id, surveyName } = survey
            setFormData((values: any) => ({ ...values, surveyId: id, surveyName: surveyName }))
        } else {
            setFormData((values: any) => ({ ...values, surveyId: null, surveyName: null }))
        }
    }

    const onCsvParse = (results: ParseResult<string[]>) => {
        if (results.errors.length > 0) {
            console.error(`Error: ${results.errors.map(e => e.message).join()}`)
            return;
        }

        setFormData((values: any) => ({ ...values, samples: results.data }))
    }

    const handleCsv = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];
        const results: ParseResult<string[]> = await Papa.parseAll(file);
        onCsvParse(results)
    }

    const handleClick = async (event: React.FormEvent) => {
        event.preventDefault();

        setResponses(await createSamples(formData))
        setFormData({})

        inputRef.current.value = "";
    }

    const isFullySuccess = responses.length ? responses.every((response) => response.isSuccessful === true) : false;
    const success = responses.filter(res => res.isSuccessful);
    const errors = responses.filter(res => !res.isSuccessful);
    const reducedErrors = errors.reduce((acc: Record<string, any>, current: any) => {
        current.errorMessages.forEach((error: string) => {
            if (error && !acc[error]) {
                acc[error] = {
                    samples: []
                };
            }
            acc[error].samples.push(current.label);
        });
        return acc;
    }, {});

    return (
        <>
            <form onSubmit={handleClick}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {isFullySuccess && <Alert severity={"success"}><AlertTitle>Record created successfully for physical sample reference</AlertTitle></Alert>}
                                {!isFullySuccess && success.length ?
                                    <Alert severity={"success"}>
                                        <AlertTitle>
                                            <div>Record created successfully for physical sample reference:</div>
                                            <Divider sx={{ m: 2 }} />
                                            <span>{success && success.join(', ')}</span>
                                        </AlertTitle>
                                    </Alert>
                                    : null}
                                {errors.length ?
                                    <Alert severity={"error"}>
                                        <AlertTitle>
                                            <div>Error creating record for physical sample reference:</div>
                                            <Divider sx={{ m: 2 }} />
                                            {Object.keys(reducedErrors).map((errorKey: any, index: number) => {
                                                const error = reducedErrors[errorKey];
                                                return <div>
                                                    <div>{errorKey}</div>
                                                    <span>{error.samples && error.samples.join(', ')}</span>
                                                    <Divider sx={{ m: 2 }} />
                                                </div>
                                            })}
                                        </AlertTitle>
                                    </Alert> : null}
                            </Grid>
                            <Grid item xs={12}>
                                <h1>Upload Samples</h1>
                            </Grid>
                            <Grid item id={"projects"} xs={12}>
                                <Project value={formData.projectName || null} handleChange={handleProjectChange} />
                            </Grid>
                            <Grid item id={"surveys"} xs={12}>
                                <Survey value={formData.surveyName || null} handleChange={handleSurveyChange}
                                    project={project} />
                            </Grid>
                            <Grid item id={"upload"} xs={12}>
                                <UploadButton inputRef={inputRef} handleFile={handleCsv} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="warning" type={"submit"}>Submit</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <a href="https://naturalhistorymuseum.sharepoint.com/:x:/r/sites/NHM-UNP-DataEcosystemworkstream/_layouts/15/Doc.aspx?sourcedoc=%7B7E31748F-A613-46AE-A9F3-D2572C4825DF%7D&file=eDNA%20Sample%20upload%20format.csv&action=default&mobileredirect=true" target="_blank" rel="noopener noreferrer">
                                    Click here to view sample file format</a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export default UploadSamplesForm;