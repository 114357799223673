import * as React from 'react';
import {useState} from 'react';
import {Alert, Button} from "@mui/material";
import LinearProgressWithLabel from "../../../components/common/LinearProgressWithLabel";
import {
    completeMultipartUpload,
    createNewDeployment,
    getActiveDeploymentForSensor,
    initiateAudioZipUpload,
    retrieveUploadUrlAudioZipUploadChunk,
    uploadFile
} from "../../../services/apiCalls";
import {PartResponse, Sensor} from "../../../customTypes/AcousticsAPIType";
import {Deployment, DeploymentCreation} from "../../../customTypes/MetadataAPIType";
import SamplingProtocolType from "../../../customTypes/SamplingProtocolType";

function FileUploadComponent(props: {
    onSuccessfulUpload: () => void,
    audioZipFile: File | null,
    configObject: object | null,
    disableFileUploadButton: boolean,
    sensor: Sensor | null,
    samplingProtocol: SamplingProtocolType | null,
    uploadInProgress: boolean,
    setUploadInProgress: (arg0: boolean) => void,
    setUploadComplete: (argo0: boolean) => void
}) {
    const [progress, setProgress] = useState(0);
    const [errors, setErrors] = useState<Array<string>>([]);
    const chunkSize = 10 * 1024 * 1024;

    const handleChunkUpload = async (fileToUpload: File, sensorId: string) => {
        const totalChunks = Math.ceil(fileToUpload.size / chunkSize);
        const chunkProgress = 100 / totalChunks;

        const multipart = await initiateAudioZipUpload(fileToUpload.name, sensorId, totalChunks).catch((err) => {
            throw new Error("Error initiating upload. Please ensure the filename is unique for this sensor.");
        });

        props.setUploadInProgress(true);

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const chunk = fileToUpload.slice(chunkIndex * chunkSize, (chunkIndex + 1) * chunkSize);
            const partNumber = chunkIndex + 1;

            const partResponse: PartResponse = await retrieveUploadUrlAudioZipUploadChunk(multipart.id, partNumber).catch((err) => {
                throw new Error("Error retrieving upload url for chunk");
            });

            await uploadFile(new File([chunk], `${multipart.id}_part_${partNumber}.part`), { url: partResponse.upload_url });
            console.log(`Chunk ${partNumber}/${totalChunks} uploaded successfully`);
            setProgress(partNumber * chunkProgress);
        }
        setProgress(100)
        await completeMultipartUpload(multipart.id);
        console.log('Multipart upload completed');
        setProgress(0);
    };

    const handleDeploymentUpdate = async (configJson: object, currentDeployment: Deployment) => {
        let currentConfig = currentDeployment.deployment_config;
        currentConfig = { ...currentConfig, "audio_moth_config": configJson };

        const newDeployment: DeploymentCreation = {
            device: currentDeployment.device,
            survey_id: currentDeployment.survey_id,
            deployed_by: currentDeployment.deployed_by,
            sensors: currentDeployment.sensors,
            deployment_config: currentConfig
        };
        return await createNewDeployment(newDeployment, currentDeployment.id);
    };

    const handleSubmit = async () => {
        try {
            if (!props.audioZipFile || !props.sensor  || !props.samplingProtocol) {
                setErrors(prevErrors => [...prevErrors, "Sensor, Sampling Protocol or Zip File not included"]);
                return;
            }

            if (props.audioZipFile.size < 5 * 1024 * 1024) {
                setErrors(prevErrors => [...prevErrors, "Please upload a zip file greater than 5MB"]);
                return;
            }

            const deployment = await getActiveDeploymentForSensor(props.sensor.id);
            if (!deployment) {
                setErrors(prevErrors => [...prevErrors, `Error retrieving deployment for sensor: ${props.sensor!.id}. Please ensure it is associated with an active deployment.`]);
                return;
            }

            if (props.configObject) {
                const headers = await handleDeploymentUpdate(props.configObject, deployment);
                if (!headers) {
                    console.log('Error updating deployment');
                    return;
                }
            }

            props.setUploadComplete(false);
            props.setUploadInProgress(false);
            setErrors([]);

            await handleChunkUpload(props.audioZipFile, props.sensor.id);
            console.log('Upload completed');
            props.setUploadInProgress(false);
            props.setUploadComplete(true);
            props.onSuccessfulUpload();
        } catch (err) {
            let message = 'Unknown Error'
            if (err instanceof Error) {
                message = err.toString()
            }
            setErrors(prevErrors => [...prevErrors, message]);
        }
    };


    return (
        <div className="acoustics-modal-row">
            {props.uploadInProgress ?
                <>
                    <h3>Upload Progress</h3>
                    <div>
                        <LinearProgressWithLabel progress={progress}/>
                    </div>
                </>
                :
                <>
                    <Button size="large" variant="contained" color="warning" onClick={handleSubmit}
                            disabled={props.disableFileUploadButton}>Submit Acoustic Samples</Button>
                    {errors.length !== 0 ?
                        <Alert variant='filled' severity='error'>{errors[errors.length-1]}</Alert> : <></>
                    }
                </>
            }
        </div>
    )
}

export default FileUploadComponent;
