import React from "react";
import {IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function Licenses(props: {value: string | undefined, handleChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void})  {
    return(
        <TextField
            name="licenses"
            value={props.value}
            onChange={(event) => props.handleChange(event)}
            label="Licenses"
            variant="outlined"
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <Tooltip placement="top" arrow title="This is a comma separated list of the licences under which the data coming out of this project will be published under.">
                        <IconButton edge="end"><HelpOutlineIcon/></IconButton>
                    </Tooltip>
                </InputAdornment>
            }}
        />
    )
}

export default Licenses;