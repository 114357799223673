import { RawAxiosResponseHeaders } from "axios";
import APIResponseType from "../customTypes/APIResponseType";
import { CreateSamplingProtocolType } from "../customTypes/CreateSamplingProtocolType";
import SamplingProtocolType from "../customTypes/SamplingProtocolType";
import {getAllSamplingProtocols} from "./apiCalls";
import { getAxiosAuth } from "./axiosWrapper";

const samplingProtocolNameAndId = (apiResponse: any) => {
    return apiResponse.reduce((arr: any, currentValue: any) => {
        arr.push({
            samplingProtocolName: currentValue.sampling_protocol_name,
            id: currentValue.id
        })

        return arr;
    }, [])
}

export async function fetchSamplingProtocols(): Promise<Array<SamplingProtocolType>>{
    const samplingProtocolApiResponse = await getAllSamplingProtocols()

    return await (samplingProtocolNameAndId(samplingProtocolApiResponse));
}

function getMessages(err: any) {
    return err.response.data.violations.map((violation: any) => {
        return violation.field.replace("create.sampling_protocol.", "") + " " + violation.message
    });
}

async function postSamplingProtocol(payload: CreateSamplingProtocolType): Promise<RawAxiosResponseHeaders> {
    try {
        const response = await (await getAxiosAuth()).post(`/metadata-app/sampling-protocols`, payload)
        return response.headers
    } catch (e) {
        throw e
    }
}

export async function createSamplingProtocol(formData: any): Promise<APIResponseType<void>> {
    const payload: CreateSamplingProtocolType = {
        sampling_protocol_name: formData.samplingProtocolName,
        sampling_protocol_description: formData.description,
        owner: formData.owner,
        collection_method: formData.collectionMethod,
        sample_type: formData.sampleType,
        info_documents: formData.infoDocuments
    }

    try {
        await postSamplingProtocol(payload)
        return {isSuccessful: true}
    } catch (err: any) {
        const errorMessages = err.response.data.detail ? [err.response.data.detail] : getMessages(err)
        return {isSuccessful: false, errorMessages: errorMessages}
    }
}