import React from "react";
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TextField} from "@mui/material";
import {Dayjs} from "dayjs";

function Time(props: {value: Dayjs | null, handleTimePick: (value: Dayjs | null) => void, label: string}) {
    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                label={props.label}
                value={props.value}
                onChange={props.handleTimePick}
                renderInput={(params) => <TextField {...params} fullWidth/>}
            />
        </LocalizationProvider>
    )
}

export default Time;