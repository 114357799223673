import * as apiCalls from "./apiCalls";
import {postSample} from "./apiCalls";
import SampleType from "../customTypes/SampleType";
import APIResponseType from "../customTypes/APIResponseType";
import SampleIdMap from "../customTypes/SampleIdMap";


export async function createSamples(formData: any) {

    let responses: APIResponseType<void>[] = [];

    for (const sample of formData.samples.slice(1)) {

        if (validatePhysicalSampleReferenceId(sample)) {

            let email = sample[7] !== undefined && sample[7].length ? sample[7] : undefined;
            let time = sample[5] !== undefined && sample[5].length ? sample[5] : undefined;

            let payload: SampleType = {
                sample: {
                    survey_id: formData.surveyId,
                    physical_sample_reference_id: sample[0],
                    site_name: sample[1],
                    site_location: {latitude: sample[2], longitude: sample[3]},
                    date_of_sample_collection: sample[4],
                    time_of_sample_collection: time,
                    sample_collector: {name: sample[6], email: email},
                    weather: sample[8],
                    field_notes: sample[9]
                }
            }

            try {
                await postSample(payload);
                responses.push({
                    isSuccessful: true,
                    label: payload.sample.physical_sample_reference_id
                })
            } catch (err: any) {
                if (err.response.status === 400) {
                    const errorMessages = err.response.data.detail ? [err.response.data.detail] : getMessages(err)
                    responses.push({
                        isSuccessful: false,
                        errorMessages: errorMessages,
                        label: payload.sample.physical_sample_reference_id
                    });
                } else {
                    responses.push({
                        isSuccessful: false,
                        errorMessages: [err.message],
                        label: payload.sample.physical_sample_reference_id
                    })
                }
            }
        } else {
            responses.push({
                isSuccessful: false,
                errorMessages: ["Physical Sample Reference Id is not valid"],
                label: "unknown"
            });
        }
    }
    return responses;
}

function validatePhysicalSampleReferenceId(sample: any) {
    return !(sample[0] === undefined || sample[0].length === 0);
}

function getMessages(err: any) {
    return err.response.data.violations.map((violation: any) => {
        return violation.message
    });
}

export async function getSampleIdMapForSurveyId(surveyId: string): Promise<SampleIdMap> {
    let samplesApiResponse = await apiCalls.getAllSamplesBySurveyId(surveyId);
    return samplesApiResponse
        .reduce((previousValue, currentValue) => {
            return {
                ...previousValue,
                [currentValue.physical_sample_reference_id]: currentValue.id,
            }
        }, {})
}



