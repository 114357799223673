import {AxiosResponse, RawAxiosResponseHeaders} from "axios";
import {CreateSurveyType} from "../customTypes/CreateSurveyType";
import {ISamplingProtocol} from "../customTypes/SamplingProtocolsApiResponseType";
import {IProject} from "../customTypes/ProjectsApiResponseType";
import {ISurvey} from "../customTypes/SurveysApiResponseType";
import {FullObservationResponse} from "../customTypes/ObservationsApiResponseType";
import SampleType from "../customTypes/SampleType";
import PageType from "../customTypes/PageType";
import SampleResponseType from "../customTypes/SampleResponseType";
import IProcessingProtocol from "../customTypes/FullProcessingProtocol";
import CreateProcessingOutputType from "../customTypes/CreateProcessingOutputType";
import FullProcessingOutput from "../customTypes/FullProcessingOutput";
import CreateObservationsType from "../customTypes/CreateObservationsType";
import {getAxios, getAxiosAuth} from "./axiosWrapper";
import {
    CommunityScienceProcessingOutputRequestType,
    CommunityScienceProcessingOutputResponseType,
    CommunityScienceTVKLookupResponseType,
    CommunityScienceVerificationRecordRequestType,
    ICSSample
} from "../customTypes/CommunityScienceSamplesApiResponseType";
import {FullProcessingOutputApiResponseType} from "../customTypes/ProcessingOutputApiReponseType";
import {AcousticsProcessingProtocol, AcousticsSamplingProtocol, Multipart, PartResponse, Sensor} from "../customTypes/AcousticsAPIType";
import {Deployment, DeploymentCreation} from "../customTypes/MetadataAPIType";
import { EnvironmentalMonitoringSamplingProtocol } from "../customTypes/EnvironmentalMonitoringAPIType";

export async function getCSSamples(surveyId: string): Promise<Array<ICSSample>> {
    let url = `/community-science/samples?survey_id=${surveyId}&sort=id,desc`

    const createUrl = (page: number) => `${url}&page=${page}`;

    try {
        return await getAllPages(createUrl)
    } catch (err) {
        throw new Error("Fetch community science samples error: " + err)
    }
}

export async function getIndividualCSSample(sampleId: string): Promise<ICSSample> {
    let url = `/community-science/samples/${sampleId}`
    try {
        return await performGetRequest(url)
    } catch (err) {
        throw new Error("Fetch community science samples error: " + err)
    }
}

export async function getAvailableMediaForProcessingOutput(processingOutputId: string): Promise<Array<string>> {
    let url = `/community-science/processing-outputs/${processingOutputId}/available-media`
    try {
        return await performGetRequest(url)
    } catch (err) {
        throw new Error("Fetch community science samples error: " + err)
    }
}

export async function postCommunityScienceProcessingOutput(payload: CommunityScienceProcessingOutputRequestType): Promise<CommunityScienceProcessingOutputResponseType> {
    try {
        return (await postEntity("/community-science/processing-outputs", payload)).data
    } catch (e) {
        throw e
    }
}

export async function getCommunityScienceProcessingOutput(processingOutputId: string): Promise<CommunityScienceProcessingOutputResponseType> {
    try{
        return (await performGetRequest(`/community-science/processing-outputs/${processingOutputId}`))
    } catch (e) {
        throw e
    }
}

export async function postCommunityScienceVerificationRecord(payload: CommunityScienceVerificationRecordRequestType, processingOutputId: string): Promise<CommunityScienceProcessingOutputResponseType> {
    try {
        return (await postEntity(`/community-science/processing-outputs/${processingOutputId}/verification-records`, payload)).data
    } catch (e) {
        throw e
    }
}

export async function getListOfCSProcessingOutputsBySampleIdAndTaxonVersionKey(sampleId: string, taxonVersionKey: string): Promise<Array<CommunityScienceProcessingOutputResponseType>> {
    let url = `/community-science/processing-outputs?sample_id=${sampleId}&taxon_version_key=${taxonVersionKey}`
    try {
        return await performGetRequest(url)
    } catch (err) {
        throw new Error("Fetch community science processing outputs error: " + err)
    }
}

export async function getAllProjects(): Promise<Array<IProject>> {
    const createUrl = (page: number) => `/metadata-app/projects?sort=_id,desc&page=${page}`;
    try {
        return await getAllPages(createUrl)
    } catch (err) {
        throw new Error("Fetch projects error")
    }
}

export async function performTVKLookup(tvk: string): Promise<CommunityScienceTVKLookupResponseType> {
    const url = `/taxonomic-equivalence-engine/tvk_lookup?tvk=${tvk}`;
    try {
        return await performGetRequest(url)
    } catch (err) {
        throw new Error("TVK Lookup error")
    }

}

export async function getAllSurveys(): Promise<Array<ISurvey>> {
    const createUrl = (page: number) => `/metadata-app/surveys?sort=_id,desc&page=${page}`;
    try {
        return await getAllPages(createUrl)
    } catch (err) {
        throw new Error("Fetch surveys error")
    }
}

export async function getAllObservations(): Promise<Array<FullObservationResponse>> {
    const createUrl = (page: number) => `/observations-app/observations?sort=_id,desc&page=${page}`;
    try {
        return await getAllPages(createUrl, 3)
    } catch (err) {
        throw new Error("Fetch observations error")
    }
}

export async function getSurveysByProjectId(projectId: string): Promise<Array<ISurvey>> {
    const createUrl = (page: number) => `/metadata-app/surveys?project_id=${projectId}&page=${page}`;
    try {
        return await getAllPages(createUrl)
    } catch (err) {
        throw new Error("Fetch surveys error")
    }
}

export async function getAllSamplingProtocols(): Promise<Array<ISamplingProtocol>> {
    const createUrl = (page: number) => `/metadata-app/sampling-protocols?sort=_id,desc&page=${page}`;
    try {
        return await getAllPages(createUrl)
    } catch (err) {
        throw new Error("Fetch sampling protocol error")
    }
}

export async function postSurvey(payload: CreateSurveyType): Promise<RawAxiosResponseHeaders> {
    try {
        return (await postEntity("/metadata-app/surveys", payload)).headers
    } catch (e) {
        throw e
    }
}

export async function postProcessingOutput(payload: CreateProcessingOutputType): Promise<RawAxiosResponseHeaders> {
    try {
        return (await postEntity("/observations-app/processing-outputs", payload)).headers
    } catch (e) {
        throw e
    }
}

export async function getAllProcessingOutputs(): Promise<Array<FullProcessingOutput>> {
    const createUrl = (page: number) => `/observations-app/processing-outputs?page=${page}`;
    try {
        return await getAllPages(createUrl)
    } catch (e) {
        throw new Error("Fetch processing outputs error")
    }
}

export async function getAllFullProcessingOutputs(): Promise<Array<FullProcessingOutputApiResponseType>> {
    const createUrl = (page: number) => `/observations-app/processing-outputs?page=${page}`;
    try {
        return await getAllPages(createUrl)
    } catch (e) {
        throw new Error("Fetch processing outputs error")
    }
}

export async function getSingleFullProcessedOutput(processingOutputId: string): Promise<FullProcessingOutputApiResponseType> {
    const url = `/observations-app/processing-outputs/${processingOutputId}`;
    try {
        return await performGetRequest(url)
    } catch (e) {
        throw new Error("Fetch processing outputs error")
    }
}

export async function postSample(payload: SampleType): Promise<RawAxiosResponseHeaders> {
    try {

        return (await postEntity("/observations-app/samples", payload)).headers
    } catch (e) {
        throw e
    }
}

export async function getAllSamplesBySurveyId(surveyId: string): Promise<Array<SampleResponseType>> {
    const createUrl = (page: number) => `/observations-app/samples?survey_id=${surveyId}&page=${page}`;
    try {
        return await getAllPages(createUrl)
    } catch (e) {
        throw new Error("Fetch samples error")
    }

}

type PresignedUrl = {
    url: string
}

export async function uploadFile(file: File, presignedUrl: PresignedUrl): Promise<void> {
    try {
        await (await getAxios()).put(presignedUrl.url, file, {
            headers: {
                'Content-Type': 'application/octet-stream'
            }
        });
    } catch (err) {
        throw new Error("Upload file error")
    }
}

export type CreateMedia = {
    filename: string,
}

export type MediaCreated = {
    upload_url: string,
    download_url: string,
}

export async function postMedia(createMedia: CreateMedia): Promise<MediaCreated> {
    try {
        const response = await (await getAxiosAuth()).post("/observations-app/media", createMedia);
        return response.data
    } catch (err) {
        throw new Error("Failed to create media")
    }
}

async function postEntity<T>(url: string, payload: T, contentType?: string | undefined): Promise<AxiosResponse> {
    try {
        let response
        if(contentType === undefined) {
            response = await (await getAxiosAuth()).post(
                url,
                payload
            )
        } else {
            response = await (await getAxiosAuth()).post(
                url,
                payload,
                {
                    headers: {
                        'Content-Type': contentType
                    }
                }
            )
        }
        return response
    } catch (e) {
        throw e
    }
}

async function getAllPages<T>(createUrl: (page: number) => string, maxPages: number = 0): Promise<Array<T>> {
    let allEntries: T[] = [];
    let page: PageType<T> = { content: [], totalPages: 1, pageNumber: -1 }
    while (page.pageNumber < page.totalPages - 1) {
        const response = await (await getAxiosAuth()).get(createUrl(page.pageNumber + 1));
        page = response.data;
        allEntries.push(...page.content);

        if (page.pageNumber === (maxPages - 1)) {
            break;
        }
    }

    return allEntries;
}

async function performGetRequest<T>(url: string): Promise<T> {
    const response = await (await getAxiosAuth()).get(url);
    return response.data;
}

async function performBodylessPostRequest<T>(url: string): Promise<T> {
    const response = await (await getAxiosAuth()).post(url);
    return response.data;
}

export async function getAllProcessingProtocols(): Promise<Array<IProcessingProtocol>> {
    const createUrl = (page: number) => `/metadata-app/processing-protocols?sort=_id,desc&page=${page}`;

    try {
        return await getAllPages(createUrl)
    } catch (e) {
        throw new Error("Fetch processing protocols error")
    }
}

export async function postBulkObservations(payload: CreateObservationsType): Promise<RawAxiosResponseHeaders> {
    try {
        const req = { observations: { observationList: payload } }
        return (await postEntity("/observations-app/observations/bulk", req)).headers
    } catch (e) {
        throw e
    }
}

export async function postBulkObservationsViaMultipartFormdata(payload: CreateObservationsType): Promise<RawAxiosResponseHeaders> {
    try {
        const data = JSON.stringify(payload)
        const formData = new FormData()
        formData.append('listOfObservations', new Blob([data]))
        console.log(formData)
        return (await postEntity("/observations-app/observations/bulk", formData, "multipart/form-data")).headers
    } catch (e) {
        throw e
    }
}

export async function getSampleIdByPhysicalSampleReferenceIdAndSurveyId(surveyId: string, physicalSampleReferenceId: string): Promise<SampleResponseType> {
    try {
        const response = await (await getAxiosAuth()).get(`/observations-app/samples?survey_id=` + surveyId + `&physical_sample_reference_id=` + physicalSampleReferenceId)
        return response.data.content[0]
    } catch (err) {
        throw new Error("Fetch sample id error")
    }
}

type Media = {
    url: string
}

export async function getMediaDownloadUrl(relativeUrl: string): Promise<Media> {
    const isAllowed = relativeUrl.match(/^\/[a-z-]+\/media\/[0-9a-f]+$/);
    if (!isAllowed) {
        throw new Error(`we do not allow redirects to unknown locations`)
    }
    try {
        const response = await (await getAxiosAuth()).get(relativeUrl)
        return response.data
    } catch (err) {
        throw new Error("get media failed")
    }
}

export async function getAWSS3Link(relativeUrl: string): Promise<Media> {
    try {
        return performGetRequest(relativeUrl + "?mediaLinkType=render")
    } catch (err) {
        throw new Error("get media failed")
    }
}

export async function getSensors(sensorTypeId?: number): Promise<Array<Sensor>> {
    try{
        let baseUrl = '/metadata-app/sensors';
        if(sensorTypeId === undefined) {
            baseUrl = baseUrl + `?sort=_id,desc&page=`
        }
        else{
            baseUrl = baseUrl + `?sensor_type_id=${sensorTypeId}?sort=_id,desc&page=`;
        }
        const createUrl = (page: number) => baseUrl + page;
        return await getAllPages(createUrl)
    } catch (err) {
        throw new Error("Fetch sensors error")
    }
}

export async function initiateAudioZipUpload(filename: string, sensorId: string, numberOfParts: number): Promise<Multipart> {
    try{
        let baseUrl = '/acoustics-app/audio-moth-uploads';
        return performBodylessPostRequest(baseUrl + `?filename=${filename}&sensor_id=${sensorId}&number_of_parts=${numberOfParts}`)
    } catch (err) {
        throw new Error("Initiate Zip upload error")
    }
}

export async function retrieveUploadUrlAudioZipUploadChunk(multipartId: string, partNumber: number): Promise<PartResponse> {
    try{
        let baseUrl = '/acoustics-app/audio-moth-uploads';
        return performGetRequest(baseUrl + `/${multipartId}/part?part_number=${partNumber}`)
    } catch (err) {
        throw new Error("Retrieve part error.")
    }
}

export async function completeMultipartUpload(multipartId: string): Promise<Multipart> {
    try{
        let baseUrl = '/acoustics-app/audio-moth-uploads';
        return performBodylessPostRequest(baseUrl + `/${multipartId}/complete`)
    } catch (err) {
        throw new Error("Complete multipart upload error.")
    }
}

export async function getActiveDeploymentForSensor(sensorId: string): Promise<Deployment | null> {
    try{
        let baseUrl = '/metadata-app/deployments';
        const response = await (await getAxiosAuth()).get(baseUrl + `?sensor_id=${sensorId}&ended=false`)
        return response.data.content[0]
    } catch (err) {
        throw new Error("Complete multipart upload error.")
    }
}

export async function createNewDeployment(deployment: DeploymentCreation, deploymentToReplace: string | null): Promise<RawAxiosResponseHeaders> {
    try{
        let baseUrl = '/metadata-app/deployments';
        if(deploymentToReplace !== null) {
            baseUrl = baseUrl + `?update_id=${deploymentToReplace}`
        }
        return (await postEntity(baseUrl, deployment)).headers
    } catch (err) {
        throw new Error("Complete multipart upload error.")
    }
}

export async function postAcousticsProcessingProtocol(payload: AcousticsProcessingProtocol): Promise<RawAxiosResponseHeaders> {
    try {
        const response = await (await getAxiosAuth()).post(`/acoustics-app/processing-protocols`, payload)
        return response.headers
    } catch (e) {
        throw e
    }
}

export async function getAllAcousticsProcessingProtocols(): Promise<Array<AcousticsProcessingProtocol>> {
    const createUrl = (page: number) => `/acoustics-app/processing-protocols?sort=_id,desc&page=${page}`;

    try {
        return await getAllPages(createUrl)
    } catch (e) {
        throw new Error("Fetch processing protocols error")
    }
}

export async function postAcousticsSamplingProtocol(payload: AcousticsSamplingProtocol): Promise<RawAxiosResponseHeaders> {
    try {
        const response = await (await getAxiosAuth()).post(`/acoustics-app/sampling-protocols`, payload)
        return response.headers
    } catch (e) {
        throw e
    }
}

export async function getAllAcousticsSamplingProtocols(): Promise<Array<AcousticsSamplingProtocol>> {
    const createUrl = (page: number) => `/acoustics-app/sampling-protocols?sort=_id,desc&page=${page}`;

    try {
        return await getAllPages(createUrl)
    } catch (e) {
        throw new Error("Fetch sampling protocols error")
    }
}

export async function postEnvironmentalMonitoringSamplingProtocol(payload: EnvironmentalMonitoringSamplingProtocol): Promise<RawAxiosResponseHeaders> {
    try {
        const response = await (await getAxiosAuth()).post(`/environmental-monitoring-app/sampling-protocols`, payload)
        return response.headers
    } catch (e) {
        throw e
    }
}

export async function getAllEnvironmentalMonitoringSamplingProtocols(): Promise<Array<EnvironmentalMonitoringSamplingProtocol>> {
    const createUrl = (page: number) => `/environmental-monitoring-app/sampling-protocols?sort=_id,desc&page=${page}`;

    try {
        return await getAllPages(createUrl)
    } catch (e) {
        throw new Error("Fetch sampling protocols error")
    }
}