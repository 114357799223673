import React from "react";
import UploadSamplesForm from "./components/samples/UploadSamplesForm";

function UploadSamples() {

    return (
        <UploadSamplesForm/>
    );

}

export default UploadSamples
