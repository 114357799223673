import { Box, Button, Chip, Divider, Drawer, Grid, IconButton, Snackbar, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AcousticsSamplingProtocol } from '../../../../customTypes/AcousticsAPIType';
import { getAllAcousticsSamplingProtocols } from '../../../../services/apiCalls';


const renderHeader = ({ field }: GridColumnHeaderParams) => {
    return <Typography variant="overline" display="block">{field}</Typography>;
};



function ViewAcousticsSamplingProtocols() {
    const [samplingProtocols, setSamplingProtocols] = useState<Array<AcousticsSamplingProtocol>>([])
    const [errors, setErrors] = useState<Array<string | undefined>>([]);
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
    const [toggleDrawer, setToggleDrawer] = useState<boolean>(false);
    const [selectedSamplingProtocol, setSelectedSamplingProtocol] = useState<AcousticsSamplingProtocol>();

    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: 'id',
            flex: 0.5,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { id } = params.row;
                return <Box onClick={(e: any) => e.stopPropagation()}>
                    <CopyToClipboard text={id} onCopy={() => setOpenSnackBar(true)}>
                        <Tooltip title={id}>
                            <IconButton color="primary" component="label" size="small">
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                </Box>
            }
        },
        {
            field: 'Sampling Protocol',
            flex: 4,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { protocol_name, description } = params.row;
                return <div>
                    <Typography variant="h6">{protocol_name}</Typography>
                    <Typography variant="subtitle2">{description.length > 75 ? `${description.substring(0, 75)}...` : description}</Typography>
                </div>
            },
        },
        {
            field: 'Owner',
            flex: 4,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { name, email } = params.row.owner;
                return <div>
                    <Typography variant="h6">{name}</Typography>
                    <Typography variant="subtitle2">{email}</Typography>
                </div>
            },
        },  
    ];

    useEffect(() => {
        showAllSamplingProtocols()
    }, []);

    const handleRowClick = (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        setSelectedSamplingProtocol(params.row);
        setToggleDrawer(true);
    }

    const showAllSamplingProtocols = async () => {
        try {
            const samplingProtocols = await getAllAcousticsSamplingProtocols()
            setSamplingProtocols(samplingProtocols);
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    return (
        <Grid container direction="column" style={{ paddingLeft: 20 }}>
            <Grid item id="heading">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h3" gutterBottom>Sampling Protocols</Typography>
                    <Button variant="contained" color="warning" startIcon={<AddCircleIcon />} onClick={() => navigate('/acoustics/protocols/sampling/new')}>New Sampling Protocol</Button>
                </Stack>
            </Grid>
            <Grid item id="data" height={600}>
                <DataGrid
                    rows={samplingProtocols}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    onRowClick={handleRowClick}
                />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={openSnackBar}
                    onClose={() => setOpenSnackBar(false)}
                    message="ID is copied to clipboard"
                    autoHideDuration={5000}
                />
            </Grid>
            <React.Fragment key='project-drawer'>
                <Drawer
                    anchor="right"
                    open={toggleDrawer}
                    onClose={() => setToggleDrawer(false)}
                >
                    <Box sx={{ width: '25vw', p: '15px', bgcolor: '#EEE', height: '100%' }}>
                        <Typography variant='h5' gutterBottom>{selectedSamplingProtocol?.protocol_name}</Typography>
                        <Box sx={{ mb: 2, mt: 2 }}>
                            <Typography variant="overline" display="block">Sampling Protocol Id</Typography>
                            {selectedSamplingProtocol?.id && <CopyToClipboard text={selectedSamplingProtocol?.id} onCopy={(e: any) => {
                                setOpenSnackBar(true);
                            }}>
                                <span style={{ userSelect: 'all', cursor: 'pointer' }}>{selectedSamplingProtocol?.id}</span>
                            </CopyToClipboard>}
                        </Box>
                        <Typography variant="overline" display="block">Sampling Protocol Description</Typography>
                        <Typography variant="body2" gutterBottom>
                            {selectedSamplingProtocol?.description}
                        </Typography>

                        <Divider />
                    </Box>
                </Drawer>

            </React.Fragment>
        </Grid>
    )
}

export default ViewAcousticsSamplingProtocols;