import React from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TextField} from "@mui/material";
import {Dayjs} from "dayjs";

function EndDate(props: {
    value: Dayjs | null,
    handleDatePick: (newValue: Dayjs | null) => void,
    required?: boolean,
}) {
    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="End Date"
                value={props.value}
                onChange={props.handleDatePick}
                renderInput={
                    (params) => <TextField {...params}
                    required={typeof props.required === 'undefined'}
                    id="end-date"
                    name="endDate"
                    fullWidth/>
                }
                inputFormat="DD-MM-YYYY"
            />
        </LocalizationProvider>
    )
}

export default EndDate;