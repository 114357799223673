import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import Page404 from './pages/Page404';
import Dashboard from './pages/Dashboard';
import CreateSurvey from './pages/Surveys/CreateSurvey';
import CreateSamplingProtocol from './pages/Protocols/Sampling/CreateSamplingProtocol';
import CreateProject from './pages/Projects/CreateProject';
import CreateProcessingProtocol from './pages/Protocols/Processing/CreateProcessingProtocol';
import ViewProjects from './pages/Projects/ViewProjects';
import ViewSurveys from './pages/Surveys/ViewSurveys';
import ViewSamplingProtocols from './pages/Protocols/Sampling/ViewSamplingProtocols';
import ViewProcessingProtocols from './pages/Protocols/Processing/ViewProcessingProtocols';
import ViewProcessingOutputs from './pages/ProcessingOutputs/ViewProcessingOutputs';
import ViewObservations from './pages/Observations';
import ViewCSSamples from './pages/CommunityScience/Samples';
import ViewIndividualCSSample from './pages/CommunityScience/IndividualSample/ViewIndividualSample';
import EDNA from './eDNA/ProcessingOutputs';
import UploadSamples from './eDNA/UploadSamples';
import UploadProcessedOutputs from './eDNA/UploadProcessedOutputs';
import TaxonomicEquivalence from './eDNA/TaxonomicEquivalence';
import UploadObservations from './eDNA/UploadObservations';
import Sandbox from './analysisTool/Sandbox';
import Redirect from "./media/Redirect";
import Acoustics from "./pages/Acoustics";
import CreateAcousticsProcessingProtocol from "./pages/Acoustics/protocols/processing/CreateAcousticsProcessingProtocol";
import CreateAcousticsSamplingProtocol from './pages/Acoustics/protocols/sampling/CreateAcousticsSamplingProtocol';
import ViewAcousticsSamplingProtocols from './pages/Acoustics/protocols/sampling/ViewAcousticsSamplingProtocols';
import ViewAcousticsProcessingProtocols from './pages/Acoustics/protocols/processing/ViewAcousticsProcessingProtocols';
import UploadAcousticsSamples from './pages/Acoustics/upload-samples';
import EnvironmentalMonitoring from './pages/EnvironmentalMonitoring';
import ViewEnvironmentalMonitoringSamplingProtocols from './pages/EnvironmentalMonitoring/protocols/sampling/ViewEnvironmentalMonitoringSamplingProtocols';
import CreateEnvironmentalMonitoringSamplingProtocol from './pages/EnvironmentalMonitoring/protocols/sampling/CreateEnvironmentalMonitoringSamplingProtocol';

export default function Router({ user, signOut }: any) {

  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout user={user} signOut={signOut} />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        {
          path: 'projects',
          children: [
            { element: <ViewProjects />, index: true },
            { path: 'new', element: <CreateProject /> },
          ]
        },
        {
          path: 'surveys',
          children: [
            { element: <ViewSurveys />, index: true },
            { path: 'new', element: <CreateSurvey /> },
          ]
        },
        {
          path: 'protocols',
          children: [
            {
              path: 'sampling', children: [
                { element: <ViewSamplingProtocols />, index: true },
                { path: 'new', element: <CreateSamplingProtocol /> },
              ]
            },
            {
              path: 'processing', children: [
                { element: <ViewProcessingProtocols />, index: true },
                { path: 'new', element: <CreateProcessingProtocol /> },
              ]
            }
          ]
        },
        {
          path: 'processing-outputs',
          children: [
            {
              element: <ViewProcessingOutputs />,
              index: true
            }
          ]
        },
        {
          path: 'observations',
          children: [
            {
              element: <ViewObservations />,
              index: true
            }
          ]
        },
        {
          path: 'community-science',
          children: [
            {
              path: 'samples',
              element: <ViewCSSamples />
            },
            {
              path: 'samples/:sampleId',
              element: <ViewIndividualCSSample />
            }
          ]
        },
        {
          path: 'eDNA',
          children: [
            {
              path: 'processing-outputs',
              element: <EDNA />
            },
            {
              path: 'upload-samples',
              element: <UploadSamples />
            },
            {
              path: 'upload-processed-outputs',
              element: <UploadProcessedOutputs />
            },
            {
              path: 'taxonomic-equivalence',
              element: <TaxonomicEquivalence />
            },
            {
              path: 'upload-observations',
              element: <UploadObservations />
            }
          ]
        },
        {
          path: 'analysis-tool/sandbox',
          element: <Sandbox />
        },
        {
          path: 'acoustics',
          children: [
            { element: <Acoustics />, index: true },
            {
              path: 'upload-samples',
              element: <UploadAcousticsSamples />
            },
            {
              path: 'protocols/sampling', children: [
                { element: <ViewAcousticsSamplingProtocols />, index: true },
                { path: 'new', element: <CreateAcousticsSamplingProtocol /> },
              ]
            },
            {
              path: 'protocols/processing', children: [
                { element: <ViewAcousticsProcessingProtocols />, index: true },
                { path: 'new', element: <CreateAcousticsProcessingProtocol /> },
              ]
            }
          ]
        },
        {
          path: 'environmental-monitoring',
          children: [
            { element: <EnvironmentalMonitoring />, index: true },
            {
              path: 'protocols/sampling', children: [
                { element: <ViewEnvironmentalMonitoringSamplingProtocols />, index: true },
                { path: 'new', element: <CreateEnvironmentalMonitoringSamplingProtocol /> },
              ]
            },
          ]
        }
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: 'r/*', element: <Redirect /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
