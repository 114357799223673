import { Alert, AlertTitle, Button, Grid, TextField } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import UploadButton from "../../../commonComponents/UploadButton";
import { createAndUploadProcessingOutput } from "../../../services/ProcessingOutputService";
import Project from "../../../commonComponents/Project";
import Survey from "../../../commonComponents/Survey";
import ProjectType from "../../../customTypes/ProjectType";
import SurveyType from "../../../customTypes/SurveyType";
import ProcessingProtocol from "../../../commonComponents/ProcessingProtocol";
import ProcessingProtocolType from "../../../customTypes/ProcessingProtocolType";
import Date from "../../../commonComponents/Date";
import { Dayjs } from "dayjs";
import Time from "../../../commonComponents/Time";
import APIResponseType from "../../../customTypes/APIResponseType";
import SampleColumnRange from "../../../commonComponents/SampleColumnRange";
import SampleColumnRangeType from "../../../customTypes/SampleColumnRangeType";
import CreateProcessingOutputFromForm from "../../../customTypes/CreateProcessingOutputFromForm";

function parseColumnRange(columnRange: string): SampleColumnRangeType | null {
    const columnRangeArray = columnRange.split("-");

    const start = Number(columnRangeArray[0]) - 1;
    const end = Number(columnRangeArray[1]) - 1;
    if (start >= 0 && end >= start) {
        return {
            start: start,
            end: end
        }
    } else {
        return null
    }
}

export default function UploadProcessedOutputsForm() {
    const uploadButtonRef = useRef<any>(null);
    const [columnRange, setColumnRange] = useState<string>("");
    const [project, setProject] = useState<ProjectType | null>(null);
    const [survey, setSurvey] = useState<SurveyType | null>(null);
    const [processingProtocol, setProcessingProtocol] = useState<ProcessingProtocolType | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [dateOfProcessing, setDateOfProcessing] = useState<Dayjs | null>(null)
    const [timeOfProcessing, setTimeOfProcessing] = useState<Dayjs | null>(null)
    const [linkToFASTQFiles, setLinkToFASTQFiles] = useState<string>("")
    const [response, setResponse] = useState<APIResponseType<any> | null>(null)
    const [isUploading, setIsUploading] = useState<boolean>(false)

    function clearFormDataAndErrors() {
        uploadButtonRef.current.value = ""
        setColumnRange("")
        setProject(null)
        setSurvey(null)
        setProcessingProtocol(null)
        setFile(null)
        setDateOfProcessing(null)
        setTimeOfProcessing(null)
        setLinkToFASTQFiles("")
        // setResponse should not be cleared
    }

    const handleFile = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];
        setFile(file)
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setIsUploading(true)
    }

    useEffect(() => {
        if (!isUploading) {
            return
        }

        const createProcessingOutput = async () => {
            if (!survey ||
                !file ||
                !columnRange ||
                !processingProtocol ||
                !dateOfProcessing ||
                !linkToFASTQFiles) {
                setResponse({
                    isSuccessful: false,
                    title: "Error must fill in all fields correctly"
                })
                setIsUploading(false)
                return
            }

            if(file.type !== "text/csv") {
                setResponse({
                    isSuccessful: false,
                    title: "Error: file must be of type CSV."
                })
                setIsUploading(false)
                return
            }

            const parsedColumnRange: SampleColumnRangeType | null = parseColumnRange(columnRange)
            if (!parsedColumnRange) {
                setResponse({
                    isSuccessful: false,
                    title: "Error formatting column range"
                })
                setIsUploading(false)
                return
            }

            const createProcessingOutputFromForm: CreateProcessingOutputFromForm = {
                survey: survey,
                file: file,
                columnRange: parsedColumnRange,
                processingProtocol: processingProtocol,
                dateOfProcessing: dateOfProcessing,
                timeOfProcessing: timeOfProcessing,
                linkToFASTQFiles: linkToFASTQFiles
            };
            const response = await createAndUploadProcessingOutput(createProcessingOutputFromForm);
            setResponse(response)
            if (response.isSuccessful) {
                clearFormDataAndErrors();
            }
            setIsUploading(false)
        }

        createProcessingOutput()
    }, [isUploading])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <fieldset role={"fieldSet"} disabled={isUploading} style={{ border: 'none' }}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item md={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {response && <Alert severity={response.isSuccessful ? "success" : "error"}>
                                        <AlertTitle>{response.title}{response.isSuccessful ? "" : " (No entries were created)"}</AlertTitle>
                                        <ul>
                                            {response.errorMessages && response.errorMessages.map((error: string, index: number) => {
                                                return (<li key={index}>{error}</li>)
                                            })}
                                        </ul>
                                    </Alert>}
                                </Grid>
                                <Grid item xs={12}>
                                    <h1>Upload Processed Output</h1>
                                </Grid>
                                <Grid item id={"projects"} xs={12}>
                                    <Project value={project?.projectName || null} handleChange={setProject} />
                                </Grid>
                                <Grid item id={"surveys"} xs={12}>
                                    <Survey value={survey?.surveyName || null} handleChange={setSurvey} project={project} />
                                </Grid>
                                <Grid item id={"processingProtocols"} xs={12}>
                                    <ProcessingProtocol value={processingProtocol?.protocolName || null}
                                        handleChange={setProcessingProtocol} />
                                </Grid>
                                <Grid item xs>
                                    <Date value={dateOfProcessing} handleDatePick={setDateOfProcessing} label="Date of Processing" />
                                </Grid>
                                <Grid item xs>
                                    <Time value={timeOfProcessing} handleTimePick={setTimeOfProcessing} label="Time of Processing" />
                                </Grid>
                                <Grid item id={"sampleColumnRange"} xs={12}>
                                    <SampleColumnRange columnRange={columnRange || ""} onChange={setColumnRange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        value={linkToFASTQFiles}
                                        onChange={(e) => setLinkToFASTQFiles(e.target.value)}
                                        label="Link to FASTQ Files"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item id={"upload"} xs={12}>
                                    <UploadButton inputRef={uploadButtonRef} handleFile={handleFile} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type={"submit"} disabled={isUploading}>Submit</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <a href="https://naturalhistorymuseum.sharepoint.com/:x:/r/sites/NHM-UNP-DataEcosystemworkstream/Shared%20Documents/General/TW%20Handover/eDNA%20upload%20templates/eDNA%20Processed%20output%20format.csv?d=wac74c6980d044a3dbf4d01373acf6b56&csf=1&web=1&e=kqZyyk" target="_blank" rel="noopener noreferrer">
                                        Click here to view processed output file format</a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </fieldset>
            </form>
        </>
    )
}