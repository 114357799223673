import React from "react";
import {Button, Card, CardActions, CardContent, Grid, Typography} from "@mui/material";
function TaxonomicEquivalencePage() {

    const openInNewTab = (url: string | URL | undefined) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <Grid container alignItems="center" rowSpacing={2} direction="column" style={{paddingTop: 25}}>
                <h1>Taxonomic Equivalence</h1>

                <Card sx={{ maxWidth: 500 }}>
                    <CardContent>
                        <Typography variant="body1" color="text.secondary">
                            Following this link will take you to AWS where you can launch interactive Jupyter Notebooks to run the taxonomic equivalence engine.
                            <br/><br/>
                            *Please log into AWS first. If you are not already logged into AWS then this link will not work*
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="large" variant="contained" color="warning" onClick={() => {
                            openInNewTab("https://eu-west-2.console.aws.amazon.com/sagemaker/home?region=eu-west-2#/notebook-instances")
                        }}>Sage Maker</Button>
                    </CardActions>
                </Card>
            </Grid>
        </>
    );
}

export default TaxonomicEquivalencePage;