import {Button, Paper} from "@mui/material";
import {
    CommunityScienceDocumentType,
    CommunityScienceProcessingOutputResponseType,
    CommunityScienceVerificationRecordResponseType,
    CommunityScienceVerificationCompletionStatusEnum,
    TaxonResponse
} from "../../customTypes/CommunityScienceSamplesApiResponseType";
import VerificationRecordHistoryTable from "./VerificationRecordHistoryTable";
import VerificationRecordActiveTable from "./VerificationRecordActiveTable";
import {useEffect, useState} from "react";
import {getListOfCSProcessingOutputsBySampleIdAndTaxonVersionKey} from "../../services/apiCalls";
import {DivStyle} from "./VerificationStyles";
import {documentImageFilter, renderVerificationCompletionStatus} from "../common/Functions";


function VerificationRecordTables(
    props: {
        sampleId: string
        taxonResponse: TaxonResponse
        processingOutputList: Array<CommunityScienceProcessingOutputResponseType>
        setProcessingOutputList: (tvk: string, processingOutputList: Array<CommunityScienceProcessingOutputResponseType>) => void
        setProcessingOutput: (processingOutput: CommunityScienceProcessingOutputResponseType) => void
        triggerVerificationModal: () => void
        createVerificationRecord: (taxonResponse: TaxonResponse) => void
        verifiedImages : Array<string>
        setIsRecordVerified: (argo0:boolean) => void
        setVerificationStatusEnum: (verificationStatusEnum :CommunityScienceVerificationCompletionStatusEnum) => void
    }
){
    const [activeVerificationRecords, setActiveVerificationRecords] = useState<Array<CommunityScienceVerificationRecordResponseType>>([]);
    const [verificationRecordHistory, setVerificationRecordHistory] = useState<Array<CommunityScienceVerificationRecordResponseType>>([]);
    const [verificationRecordID, setVerificationRecordID] = useState<String>("");
    const [verificationTable, setVerificationTable] = useState<Boolean>(false);
    const [errors, setErrors] = useState<Array<string>>([]);
    const [activeLoading, setActiveLoading] = useState<Boolean>(true);
    const [historyLoading, setHistoryLoading] = useState<Boolean>(true);
    const [isRecordVerified, setIsRecordVerified] = useState<boolean>(false);

    const [noOfProcessingOutputListVerifiedMedia, setNoOfProcessingOutputListVerifiedMedia] = useState<number>(0);
    const [isActiveRecordPresent, setIsActiveRecordPresent] = useState<boolean>(false);

    const taxonVersionKey : string = props.taxonResponse.taxon_version_key!;

    const getNoOfProcessingOutputMedia = (taxon_response: TaxonResponse) => {
        let noOfImages: number = 0
        if (taxon_response.document_list) {
            taxon_response.document_list.forEach((document: CommunityScienceDocumentType) => {
                if (documentImageFilter(document)) {
                    noOfImages++
                }
            });
        }
        return noOfImages;
    }

    const noOfProcessingOutputListMedia : number = getNoOfProcessingOutputMedia(props.taxonResponse);

    const verificationRecordText = () => {
        //If media is not available for processing outputs with the same TVK and sample id
        if(noOfProcessingOutputListMedia === undefined || noOfProcessingOutputListMedia === 0){
            if(props.taxonResponse.observation_count === 0) {
                props.setVerificationStatusEnum(CommunityScienceVerificationCompletionStatusEnum.NOT_REQUIRED)
                return renderVerificationCompletionStatus("Verification not required", CommunityScienceVerificationCompletionStatusEnum.NOT_REQUIRED)
            }
            else if(isActiveRecordPresent){
                props.setVerificationStatusEnum(CommunityScienceVerificationCompletionStatusEnum.COMPLETE)
                return renderVerificationCompletionStatus("All records have been verified", CommunityScienceVerificationCompletionStatusEnum.COMPLETE)
            }
            else{
                props.setVerificationStatusEnum(CommunityScienceVerificationCompletionStatusEnum.NOT_IN_PROGRESS)
                return renderVerificationCompletionStatus("No images available. Please create at least one verification record", CommunityScienceVerificationCompletionStatusEnum.NOT_IN_PROGRESS)
            }
        }
        //If any media is available for processing outputs with the same TVK and sample id
        else{
            if(noOfProcessingOutputListMedia === noOfProcessingOutputListVerifiedMedia){
                props.setVerificationStatusEnum(CommunityScienceVerificationCompletionStatusEnum.COMPLETE)
                return renderVerificationCompletionStatus("All records have been verified", CommunityScienceVerificationCompletionStatusEnum.COMPLETE)
            }
            else if(noOfProcessingOutputListVerifiedMedia === 0){
                props.setVerificationStatusEnum(CommunityScienceVerificationCompletionStatusEnum.NOT_IN_PROGRESS)
                return renderVerificationCompletionStatus(`${noOfProcessingOutputListVerifiedMedia} out of ${noOfProcessingOutputListMedia} images has been verified. Please create a verification record.`, CommunityScienceVerificationCompletionStatusEnum.NOT_IN_PROGRESS)
            }
            else{
                props.setVerificationStatusEnum(CommunityScienceVerificationCompletionStatusEnum.IN_PROGRESS)
                return renderVerificationCompletionStatus(`${noOfProcessingOutputListVerifiedMedia} out of ${noOfProcessingOutputListMedia} images has been verified. Please create another verification record.`, CommunityScienceVerificationCompletionStatusEnum.IN_PROGRESS)
            }
        }
    }

    const renderVerificationButton = (status : boolean) => {
        if(!status){
            return (
                <div style={DivStyle("4vh", "4vh" )}>
                    <Button
                        onClick={() => {
                            props.createVerificationRecord(props.taxonResponse)
                        }}
                        variant='contained'
                    >
                        Verify Taxon
                    </Button>
                </div>
            )
        }
        else{
           return ( <></> )
        }
    }

    const processingOutputList = props.processingOutputList;
    const setProcessingOutputList = props.setProcessingOutputList;

    const convertVerificationRecordClickToProcessingOutput = (verification: string) => {
        processingOutputList.forEach(output => {
            output.verification_records?.forEach(record => {
                if(record.id===verification){
                    props.setProcessingOutput(output)
                    props.triggerVerificationModal()
                    return;
                }
            })
        })
    }

    useEffect(() => {
            try {
                setActiveLoading(true);
                getListOfCSProcessingOutputsBySampleIdAndTaxonVersionKey(props.sampleId!, taxonVersionKey!).then(
                    (processingOutputs: Array<CommunityScienceProcessingOutputResponseType>) => {
                        setProcessingOutputList(taxonVersionKey, processingOutputs)
                });
            } catch (err: any) {
                setErrors([...errors, err.toString()])
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, props.sampleId, taxonVersionKey])

    useEffect(()=> {
        let activeRecords : CommunityScienceVerificationRecordResponseType[] = [];
        if(processingOutputList!==null){
            processingOutputList!.forEach(output => {
                output.verification_records?.forEach(record => {
                    if(record.active){
                        activeRecords.push(record)
                        record.media_ids?.forEach(
                            media => {props.verifiedImages.push(media)}
                        )
                    }
                })
            })
        }
        setActiveVerificationRecords(activeRecords)
        setActiveLoading(false)
    },[processingOutputList]);

    useEffect(()=> {
        setHistoryLoading(true);
        if(verificationRecordID.length>0){
            processingOutputList!.forEach(output => {
                output.verification_records?.forEach(record => {
                    if(record.id===verificationRecordID){
                        setVerificationRecordHistory(output.verification_records)
                    }
                })
            })
        }
        setHistoryLoading(false);
    }, [verificationRecordID, processingOutputList])

    useEffect(() => {
        if(processingOutputList!==null) {
            let verifiedMedia = 0;
            let activeRecordPresent: boolean = false;
            processingOutputList?.forEach(
                output => output.verification_records?.forEach(
                    record => {
                        if (record.active) {
                            if(record.media_ids){
                                verifiedMedia = verifiedMedia + record.media_ids.length;
                            }
                            activeRecordPresent = true;
                        }
                    }
                )
            )
            setNoOfProcessingOutputListVerifiedMedia(verifiedMedia);
            setIsActiveRecordPresent(activeRecordPresent);
            if(noOfProcessingOutputListMedia===0){
                if(activeRecordPresent){
                    setIsRecordVerified(true)
                }
                else {
                    setIsRecordVerified(false);
                }
            }
            else if(noOfProcessingOutputListMedia === verifiedMedia){
                setIsRecordVerified(true);
            }
            else{
                setIsRecordVerified(false);
            }
        }
        else{
            setNoOfProcessingOutputListVerifiedMedia(0);
            setIsActiveRecordPresent(false);
            setIsRecordVerified(false);
        }
    },[processingOutputList, noOfProcessingOutputListMedia])

    useEffect(()=>{
        props.setIsRecordVerified(isRecordVerified)
    },[isRecordVerified])


    return (
        <div>
            <Paper>
                {verificationTable
                    ? <VerificationRecordHistoryTable
                        verificationRecordHistoryList={verificationRecordHistory}
                        setVerificationTable={setVerificationTable}
                        loading={historyLoading}
                    />
                    : <VerificationRecordActiveTable
                        activeVerificationRecord={activeVerificationRecords}
                        setVerificationTable={setVerificationTable}
                        setVerificationRecordID={setVerificationRecordID}
                        setProcessingOutput={convertVerificationRecordClickToProcessingOutput}
                        loading={activeLoading}
                    />
                }
                <div style={{textAlign:"center"}}>
                    {verificationRecordText()}
                </div>
            </Paper>
            {renderVerificationButton(isRecordVerified)}
        </div>
    )
}

export default VerificationRecordTables;