import APIResponseType from "../customTypes/APIResponseType";
import CreateObservationsFromForm from "../customTypes/CreateObservationsFromForm";
import Papa, {ParseResult} from "./PapaParsePromise";
import {
    getSampleIdByPhysicalSampleReferenceIdAndSurveyId,
    postBulkObservationsViaMultipartFormdata,
    postMedia,
    uploadFile
} from "./apiCalls";
import CreateObservationsType from "../customTypes/CreateObservationsType";

export async function createObservations({
                                             file,
                                             columnRange,
                                             surveyId,
                                             processingOutput
                                         }: CreateObservationsFromForm): Promise<Array<APIResponseType<any>>> {

    let responses: APIResponseType<void>[] = [];

    const results: ParseResult<string[]> = await Papa.parseAll(file);
    if (results.errors.length > 0) {
        return addErrorMessage(responses, ["Error parsing CSV values"], "");

    }

    let observations = []
    let speciesPosition = results.data[0].indexOf("species");
    if (speciesPosition === -1) {
        return addErrorMessage(responses, ["Column 'species' not found in the file"], "")
    }
    let percIdentityPosition = results.data[0].indexOf("perc_identity");

    let recordedTaxonAuth = results.data[0].indexOf("Recorded_taxon_authority");
    let recordedTaxonAttr = results.data[0].indexOf("Recorded_taxon_attribute");
    let recordedTaxonKey = results.data[0].indexOf("Recorded_taxon_key");
    let uksiRecName = results.data[0].indexOf("UKSI_recommended_name");
    let uksiRecAuth = results.data[0].indexOf("UKSI_recommended_authority");
    let uksiRecAttr = results.data[0].indexOf("UKSI_recommended_attribute");
    let uksiRecKey = results.data[0].indexOf("UKSI_recommended_key");

    const attachmentUrlResponse = await uploadAttachment(file);
    if (!attachmentUrlResponse.isSuccessful || !attachmentUrlResponse.successData) {
        return [attachmentUrlResponse]
    }

    for (let column = columnRange.start; column <= columnRange.end; column++) {
        try {
            let sample = await getSampleIdByPhysicalSampleReferenceIdAndSurveyId(surveyId, results.data[0][column])
            const sampleObservations: CreateObservationsType = []
            observations.push(sampleObservations)

            for (const line of results.data.slice(1)) {
                if (isNotBlankOrNA(line[percIdentityPosition]) &&
                    isNotBlankOrNA(line[speciesPosition]) &&
                    parseInt(line[column]) > 0) {

                    sampleObservations.push(
                        {
                            sample_id: sample.id,
                            processing_output_id: processingOutput.id,
                            observer_name: sample.sample_collector.name,
                            recorded_db_taxon_name: line[speciesPosition],
                            recorded_taxon_authority: line[recordedTaxonAuth],
                            recorded_taxon_attribute_qualifier: line[recordedTaxonAttr],
                            number_of_occurrences: line[column],
                            perc_identity: line[percIdentityPosition],
                            uksi_taxon_name: line[uksiRecName],
                            uksi_taxon_authority: line[uksiRecAuth],
                            uksi_taxon_attribute_qualifier: line[uksiRecAttr],
                            uksi_taxon_id: line[uksiRecKey],
                            evidence_link: attachmentUrlResponse.successData
                        }
                    )
                }
            }
            try {
                if (observations[observations.length - 1].length > 0) {
                    await postBulkObservationsViaMultipartFormdata(observations[observations.length - 1]);
                    responses = addSuccessMessage(responses, results.data[0][column])
                } else {
                    responses = addErrorMessage(responses, ["No valid observations to be created"], results.data[0][column])
                }
            } catch (err: any) {
                if (err.response.status === 400) {
                    const errorMessages = err.response.data.detail ? [err.response.data.detail] : getMessages(err)
                    responses = addErrorMessage(responses, errorMessages, results.data[0][column])
                } else {
                    responses = addErrorMessage(responses, [err.message], results.data[0][column])
                }
            }
        } catch (e) {
            responses = addErrorMessage(responses, ["Physical Sample Reference Id is not valid"], results.data[0][column])
        }
    }

    return responses;
}

async function uploadAttachment(file: File): Promise<APIResponseType<string>> {
    try {
        const {upload_url, download_url} = await postMedia({filename: file.name});
        await uploadFile(file, {url: upload_url})
        return {
            isSuccessful: true,
            successData: download_url,
        }
    } catch (e: any) {
        return {
            isSuccessful: false,
            title: "Error: failed to upload file",
            errorMessages: ["Error: failed to upload file"],
        }
    }
}

function isNotBlankOrNA(value: string) {
    return value !== undefined && value.length !== 0 && value !== 'NA';
}

function addSuccessMessage(responses: APIResponseType<void>[], label: string) {
    responses.push({
        isSuccessful: true,
        label: label
    })

    return responses;
}

function addErrorMessage(responses: APIResponseType<void>[], errorMessage: string[], label: string) {
    responses.push({
        isSuccessful: false,
        errorMessages: errorMessage,
        label: label
    });

    return responses;
}

function getMessages(err: any) {
    return err.response.data.violations.map((violation: any) => {
        return violation.message
    });
}