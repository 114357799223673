import Papa, {Parser, ParseResult, ParseStepResult} from "papaparse";

function parseAll(file: File): Promise<ParseResult<string[]>> {
    return new Promise(function (resolve, reject) {
        Papa.parse(file, {complete: resolve, error: reject});
    })
}

function parseFirstLine(file: File): Promise<ParseResult<string>> {
    return new Promise(function (resolve, reject) {
        Papa.parse(file, {
            step: (results: ParseStepResult<string[]>, parser: Parser) => {
                resolve(results)
                parser.abort()
            },
            error: reject
        });
    })
}

export default {
    parseAll: parseAll,
    parseFirstLine,
}

export type {ParseResult}