import { Box, Chip, Typography } from "@mui/material";
import DocumentType from "../../../customTypes/DocumentType";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export function ViewDocuments(props: {value: DocumentType[] | undefined}) {
    return (
        <>
            <Typography variant="overline" display="block">Documents</Typography>
            <Box>
                {props.value?.map(({ title, link }: DocumentType, index: number) => {
                    return <Chip
                        key={index}
                        icon={<FileDownloadIcon />}
                        size="small"
                        label={title}
                        sx={{ mr: '5px', mb: '5px' }}
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href={link}
                        onClick={(e: any) => e.stopPropagation()}
                    />
                })}
            </Box>
        </>
    )
}