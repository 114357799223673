import React from "react";
import {IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function Description(props: {
        value: string, 
        handleChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
        label?: string,
        required?: boolean,
        name?: string,
        instruction?: string
}) {
    return(
        <TextField
            required={typeof props.required === 'undefined'}
            id="description"
            name={props.name || "description"}
            value={props.value}
            onChange={props.handleChange}
            label={props.label || "Description"}
            multiline
            rows={4}
            fullWidth
            InputProps={ props.instruction ? {
                endAdornment: <InputAdornment position="end">
                    <Tooltip placement="top" arrow title={props.instruction}>
                        <IconButton edge="end"><HelpOutlineIcon/></IconButton>
                    </Tooltip>
                </InputAdornment>
            } : {} }
        />
    )
}

export default Description;