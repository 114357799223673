import { CSSProperties } from "react";

export const BoxStyle = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    height: '85vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

export const DivStyle = (height?: string, padding_top?: string, margin_bottom?: string) => {
    return {
        paddingTop: padding_top || '0vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: height || "4vh",
        marginBottom: margin_bottom || "0vh"
    }
}

export const PaperStyle = () => {
    return{ 
        height: '62vh', 
        backgroundColor: '#e0e0e0',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '1vh',
        padding: '1vh'
    }
}

export const NavStyle: CSSProperties = {
    width: '100%',
    position: 'absolute', 
    bottom: 0, 
    left: 0, 
    paddingLeft: '5vw', 
    paddingRight: '5vw', 
    paddingBottom: '2vh'
}
