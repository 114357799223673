import {
    DataGrid,
    GridColDef,
    GridEventListener
} from "@mui/x-data-grid";
import {Grid, Stack, Tooltip, Typography} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';

import {CommunityScienceVerificationRecordResponseType} from "../../customTypes/CommunityScienceSamplesApiResponseType";


const verifiedRecordsColumns: GridColDef[] = [
    { field: 'Species', headerName: 'Species', flex: 3,
        valueGetter: (params) => {
            return params.row.taxon_hierarchy!.species? params.row.taxon_hierarchy.species : 'Not Found';
        }},
    { field: 'Genus', headerName: 'Genus', flex: 3,
        valueGetter: (params) => {
            return params.row.taxon_hierarchy!.genus? params.row.taxon_hierarchy.genus : 'Not Found';
        }},
    { field: 'level_1_status', headerName: 'Level 1 Status', flex: 3 },
    { field: 'level_2_status', headerName: 'Level 2 Status', flex: 3 },
    { field: 'verified_by', headerName: 'Last edited by', flex: 3 },
    { field: 'History', headerName: 'History', flex: 2,  sortable: false, filterable: false, align: "center", headerAlign: "center",
        renderCell: () => {
            return (
                <>
                    <Tooltip title={"Click icon to view the record history"}>
                        <HistoryIcon color="primary" height={150}></HistoryIcon>
                    </Tooltip>
                </>
            )
        }},
    { field: 'Edit', headerName: 'Edit', flex: 2, sortable: false, filterable: false, align: "center", headerAlign: "center",
        renderCell: () => {
            return (
                <>
                    <Tooltip title={"Click icon to edit the record"}>
                        <EditIcon color="primary" height={150}></EditIcon>
                    </Tooltip>
                </>
            )
        }
    }
]


function VerificationRecordActiveTable(props: {
    activeVerificationRecord: Array<CommunityScienceVerificationRecordResponseType>
    setVerificationTable: (arg0: boolean) => void
    setVerificationRecordID: (args0: String) => void
    setProcessingOutput: (verificationRecord: string) => void
    loading: Boolean
}) {

    const handleRowClick: GridEventListener<'cellClick'> = (params) => {
        if(params.field === 'Edit'){
            props.setProcessingOutput(params.row.id);
        }
        if(params.field === 'History') {
            props.setVerificationTable(true);
            props.setVerificationRecordID(params.row.id);
        }
    };

    return (
        <>
            <Grid container direction="column" style={{paddingLeft: 20}}>
                <Grid item id="heading" padding="15px 0px">
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h5" gutterBottom alignContent="center">Verification Records:</Typography>
                    </Stack>
                </Grid>
                <Grid item id="verification_table" sx={{height:'40vh'}}>
                    <DataGrid
                        rows={props.activeVerificationRecord}
                        columns={verifiedRecordsColumns}
                        pageSize={10}
                        loading={props.loading.valueOf()}
                        onCellClick={handleRowClick}
                        hideFooterSelectedRowCount
                    />
                </Grid>
            </Grid>
        </>
        )
}

export default VerificationRecordActiveTable;